.button {
    color: white;
    padding: 1rem;
    background: #6750a4;
    border-radius: 100px;
    font: 500 1.4rem/2rem 'Roboto', sans-serif;
    transition: all linear 0.3s;
    &[type='submit'] {
        padding: 1rem 4rem;
    }
    &:hover {
        background-color: darken(#6750a4, 10%);
    }
    &:disabled {
        background-color: lighten(#6750a4, 20%);
    }
    &Create {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 1rem 2.5rem 1rem 1.6rem;
        &:before {
            content: '';
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            background: url('./create-icon.svg') no-repeat center;
        }
    }
    &Edit,
    &Delete {
        width: 1.5rem;
        height: 1.5rem;
        transition: all linear 0.3s;
        &:hover {
            background-color: transparent;
            scale: 1.2;
        }
    }
    &Edit {
        background: transparent url('./edit-icon.svg') no-repeat center;
    }
    &Delete {
        background: transparent url('./delete-icon.svg') no-repeat center;
    }
}
