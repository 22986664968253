.footer {
    flex: 0 0 auto;
    background-color: #383838;
    padding: 2.5rem 0;
    &Content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
    }
    &Logo {
        filter: brightness(0) invert(1);
        &Wrapper {
            margin-right: auto;
        }
    }
}

.copyright {
    color: #ffffff;
    margin: 0 auto;
}
