.inputSearch {
    border: 1px solid #b7c4cd;
    border-radius: 3px;
    width: 100%;
    padding: 1rem 1rem 1rem 4rem;
    background: url('./search-icon.svg') no-repeat 1rem center;
    &:disabled {
        background-color: #e9ecef;
    }
}
