.darkBG {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 90;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    max-width: 90rem;
    width: 100%;
    overflow-y: auto;
    z-index: 100;
}

.modal {
    background: white;
    z-index: 10;
    padding: 5rem;
    position: relative;
}

.modalHeader {
    text-align: left;
    margin-bottom: 4rem;
}

.heading {
    font: 700 2rem/2.4rem 'Roboto', sans-serif;
}

.closeBtn {
    cursor: pointer;
    border: none;
    background: transparent;
    position: absolute;
    top: 3rem;
    right: 3rem;
}
