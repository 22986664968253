.wrapper {
    padding: 5rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    & > .table {
        flex-grow: 2;
    }
}
