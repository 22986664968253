@import-normalize;

html {
    font-size: 10px;
    height: 100%;
}

body {
    height: 100%;
    background-color: #ffffff;
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    color: #232326;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul,
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
    vertical-align: middle;
    outline: none;
}

textarea {
    resize: none;
}

input[type='text'],
input[type='submit'],
input[type='search'],
textarea {
    -webkit-appearance: none;
}
input,
select,
button {
    outline: none;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

#root,
[data-amplify-theme] {
    height: 100%;
}

.container {
    margin: 0 auto;
    max-width: 144rem;
    width: 100%;
    height: 100%;
    padding: 0 6rem;
    position: relative;
    @media (max-width: 1024px) {
        padding: 0 3rem;
    }
}
