.header {
    background: #233b5f;
    &Content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 0;
    }

    &Logo {
        filter: brightness(0) invert(1);
    }
}

.nav {
    display: flex;
    align-items: center;
    gap: 6rem;
    &Link {
        color: #ffffff;
        text-transform: uppercase;
        white-space: nowrap;
        &:hover,
        &.active {
            text-decoration: underline;
        }
        &.active {
            font-weight: 700;
        }
    }
}

a.navLink:hover {
    color: #ffffff;
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.menu {
    position: absolute;
    list-style-type: none;
    margin: 0.5rem 0;
    z-index: 80;
    background-color: #233b5f;
    border: 1px solid grey;
}

.menu > li {
    padding: 1rem 0.5rem;
}

.downCaret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    display: inline-block;
    margin-left: 6px;
    top: -3px;
    position: relative;
    transform: rotate(0deg);
    transition: all 0.25s ease-in;
}

.open-caret {
    transform: rotate(180deg);
    transition: all 0.25s ease-out;
}

.logout {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    background: url('./logout-icon.svg') no-repeat center / cover;
}
