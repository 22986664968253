.wrapper {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.checkResultHeader {
    display: flex;
    align-items: center;
    gap: 3rem;
}
