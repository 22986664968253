.spreadsheet {
    &Container {
        height: 100%;
        padding: 1.5rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    &Actions {
        display: flex;
        justify-content: space-between;
    }
    &FullScreen {
        display: block;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background: transparent url('./fullscreen-icon.svg') no-repeat center / cover;
        transition: all linear 0.3s;
        &:hover {
            background-color: transparent;
            scale: 1.2;
        }
    }
}
