.table,
.td,
.th {
    border: 1px solid #d1cfd7;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.thead {
    background: #f2f5f7;
    height: 4rem;
}

.th {
    padding: 0 1rem;
    font: 700 1.3rem/1.6rem 'Roboto', sans-serif;
    white-space: nowrap;
    text-align: left;
}

.td {
    padding: 1rem;
    font: 400 1.3rem/1.6rem 'Roboto', sans-serif;
}

.clickable {
    cursor: pointer;
    transition: background-color linear 0.3s;
    &:hover {
        background-color: #e5e5e5;
    }
}
