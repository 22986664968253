.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3rem;
    column-gap: 6rem;
    align-items: flex-start;
}

.fullWidth {
    grid-column: span 2;
}

.input {
    &Wrapper {
        position: relative;
    }
    &Control {
        border: 1px solid #79747e;
        border-radius: 4px;
        position: relative;
        width: 100%;
        color: #232326;
        padding: 0.5rem 1.5rem;
        font: 400 1.6rem/2.4rem 'Roboto', sans-serif;
        &[aria-invalid='true'] {
            border-color: #dc3545;
        }
    }
    &Label {
        position: absolute;
        top: -8px;
        z-index: 1;
        left: 2rem;
        background-color: white;
        padding: 0 5px;
        font: 400 1.2rem/1.6rem 'Roboto', sans-serif;
    }
    &Error {
        position: absolute;
        color: #dc3545;
        bottom: -1.4rem;
        left: 0;
        font: 400 1rem/1.4rem 'Roboto', sans-serif;
    }
}

.fieldset {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.fieldset > .legend {
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.form .fieldset {
    border: 1px groove #79747e;
}

.action {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
}

.submit {
    align-self: flex-end;
}

@media (max-width: 768px) {
    .form {
        grid-template-columns: 1fr;
    }
}
