.wrapper {
    padding: 5rem 0;
}

.dFlex {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 3rem;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.g1 {
    gap: 1.5rem;
}

.mb3 {
    margin-bottom: 3rem;
}

.mb25 {
    margin-bottom: 2.5rem;
}

.tab {
    background-color: transparent;
    border: none;
    color: #000000;
    text-transform: capitalize;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: scale linear 0.3s;
    &:hover {
        scale: 1.1;
    }
    &Active {
        border-color: #6750a4;
    }
}

.excelLink {
    width: 1.5rem;
    height: 1.5rem;
    background: url('excel-icon.svg') no-repeat center / cover;
    border: none;
    cursor: pointer;
    transition: all linear 0.3s;
    &:hover {
        background-color: transparent;
        scale: 1.2;
    }
}
